import axios from 'axios'
import JwtService from './jwt.service'

export default {
  login (email, password) {
    return axios.post('user/login', { user: { email, password } }).then(response => {
      JwtService.saveUser(response.data.user)
      return response.data
    })
  },

  check () {
    if (JwtService.getToken()) {
      return axios.post('user/check').then(response => {
        return response.data
      }).catch(error => {
        console.log(error)
        this.logout()
      })
    }
  },

  logout () {
    if (JwtService.getToken()) {
      let token = JwtService.getToken()
      axios.post('user/logout', { token: token }).then(response => {
        return response.data
      }).catch(error => {
        console.log(error)
      })
    }
    JwtService.destroyUser()
  },

}
