<template>
  <v-container
    id="login"
    tag="section"
    class="fill-height"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="300"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-6">
                Вход
              </h1>
            </div>
          </template>

          <v-container v-if="loading">
            <div class="text-center">
              <v-progress-circular
                indeterminate
                :size="150"
                :width="8"
                color="green"
              />
            </div>
          </v-container>

          <base-material-alert
            v-if="error"
            dark
            color="error"
            dismissible
          >
            {{ error }}
          </base-material-alert>

          <v-card-text
            v-if="!loading"
            class="text-center"
          >
            <v-form>
              <v-text-field
                v-model="email"
                :error-messages="fieldError('email')"
                color="secondary"
                label="Логин или e-mail..."
                prepend-icon="mdi-email"
                required
              />

              <v-text-field
                v-model="password"
                :error-messages="fieldError('password')"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                class="mb-8"
                color="secondary"
                label="Пароль..."
                prepend-icon="mdi-lock-outline"
                @click:append="show1 = !show1"
              />

              <v-btn
                rounded
                color="success"
                type="button"
                @click="submit()"
              >
                Вход
              </v-btn>
            </v-form>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  import userApi from '../services/UserApi'

  export default {
    name: 'PagesLogin',
    components: {
      PagesBtn: () => import('./pages/components/Btn'),
    },
    data () {
      return {
        show1: false,
        email: null,
        password: null,
        error: null,
        fieldsErrors: [],
        loading: false,
      }
    },
    mounted () {
      document.title = 'Вход' + ' | ' + process.env.VUE_APP_NAME
      userApi.logout()
    },
    methods: {
      submit () {
        this.error = null
        this.fieldsErrors = []
        this.loading = true
        userApi.login(this.email, this.password)
          .then(response => {
            this.client = response
            this.loading = false
            if ('returnUrl' in this.$route.query) this.$router.push(this.$route.query.returnUrl)
            else this.$router.push('/clients/')
            console.log('redirect to clients')
          })
          .catch(error => {
            let data = error.response.data
            if (error.response.status === 422) {
              this.fieldsErrors = data
            } else if (error.response.status === 401) {
              this.error = 'Неверный логин или пароль!'
            } else {
              this.error = this.pretty(data)
            }
            this.loading = false
          })
      },
      fieldError (fieldName) {
        let errors = []
        if (this.fieldsErrors) {
          this.fieldsErrors.filter(field => {
            if (field.field === fieldName) {
              errors.push(field.message)
            }
          })
        }
        return errors
      },
      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
    },
  }
</script>
